
.link {
  @include typography(paragraph);

  color: inherit;
  position: relative;
  text-decoration: none;
  transition: color $easing-change;
  vertical-align: bottom;

  .link__span {
    display: inline-block;
    line-height: 40px;
    text-transform: uppercase;
    vertical-align: baseline;
  }

  .link__icon {
    display: inline-block;
    height: 40px;
    padding: .1em .1em .2em;
    position: relative;
    vertical-align: middle;
    width: 40px;

    > svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.link--animation-right {
  .link__icon--after {
    transition: margin-left .2s;
  }

  &:hover .link__icon--after,
  &:focus .link__icon--after {
    margin-left: 10px;
  }
}

.link--animation-press {
  .link__icon--after {
    transition: transform .1s;
  }

  &:hover,
  &:focus {
    .link__icon--after {
      transform: scale(.8);
    }
  }
}

.link--default,
.link--inverted {
  > .link__span {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    cursor: pointer;
    display: inline;
    margin-bottom: var(--heading-6-margin-bottom);
    text-decoration: none;
    transition: background-size .2s linear;
  }

  &:hover,
  &:focus {
    > .link__span {
      background-size: 100% 1px;
    }
  }
}

.link--dropdown {
  position: relative;

  .link__icon {
    height: 13px;
    position: relative;
    top: -2px;
    transition: transform 200ms ease;
    width: 13px;
  }

  .dropdown {
    transform: translateY(50px) scaleY(0);
    transform-origin: center top;
    transition: transform 400ms ease;
  }

  &:hover,
  &:focus,
  &.is-active {
    .dropdown {
      transform: translateY(0) scaleY(1);
    }

    > .link__icon {
      transform: scaleY(-1);
    }

    > .link__span {
      background-size: 0;
    }
  }
}
