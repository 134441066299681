
.container {
  position: relative;
}

.container--wrapper {
  //
}

.container--main {
  padding-top: 70px;
  transition: transform .8s;

  @include notebook {
    padding-top: 80px;
  }
}

.micrio-active .container--main {
  transform: translateX(-100%);
}

.container--header {
  //
}

.container--micrio {
  height: 100%;
  position: fixed;
  width: 100%;
}

.container--content {
  //
  z-index: 2;
}

.container--footer {
  //
  z-index: 6;
}

.container--overlays {
  //
  z-index: 8;
}
