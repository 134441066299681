
.card-carousel {
  position: relative;

  @include theme-content using ($color, $text-color) {
    .card__content {
      background-color: $color;
      color: $text-color;
    }
  }

  .card-carousel__grid {
    @include grid-container;
  }

  .card-carousel__wrapper {
    grid-column: column 1 / span 12;
    position: relative;
  }

  .card-carousel__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }

  .card-carousel__cards {
    //display: grid;
    //grid-gap: var(--grid-spacing) var(--grid-gutter);
    //grid-template-columns: unquote('repeat(auto-fill, minmax(280px, 1fr))');
    //
    //.card {
    //  flex: 1 1 33.33%;
    //  justify-self: center;
    //}
    clip-path: polygon(10px 0, calc(100% - 10px) 0, calc(100% - 10px) 100%, 10px 100%);
    margin: 0 -10px;
    width: calc(100% + 20px);

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
    }

    .card {
      flex: 1 1 100%;
      height: 100vh;
      justify-self: center;

      @include notebook {
        height: 100%;
      }
    }

    .card__content {
      color: $color-black;
      min-height: 50vh;
      padding: 20px 30px;
    }

    .card__text {
      @include typography(heading-3);

      flex: 0 1 auto;
      margin-top: 10px;
      padding-bottom: 60px;

      @include notebook {
        max-width: 75%;
        padding-bottom: 0px;
      }
    }

    .card__title-wrapper {
      @include landscape {
        max-width: 75%;
      }
    }

    .card__title {
      @include typography(heading-1);

      flex: 0 1 auto;
      font-size: 2.5em;
      line-height: .9em;
      margin-bottom: 0;

      @include landscape {
        font-size: 5em;
        line-height: .9em;
        max-width: 75%;
      }

      @include notebook {
        font-size: 7.5em;
        line-height: .9em;
        margin-bottom: 20px;
      }

      @include notebook {
        font-size: 8em;
        line-height: .9em;
        margin-bottom: 20px;
      }
    }

    .card__label {
      @include typography(paragraph);

      flex: 1 0 auto;
      margin-bottom: auto;
      text-transform: uppercase;
    }

    .card__image {
      min-height: 50vh;

      @include notebook {
        max-height: 50vh;
      }
    }
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-navigation {
    bottom: 20px;
    left: 20px;
    position: absolute;

    @include notebook {
      left: initial;
      right: 40px;
    }
  }

  .swiper-button-prev {
    // override default
    margin-right: 20px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    // override default
    background: none;
    display: inline-block;
    height: 34px;
    width: 34px;
  }

  .swiper-button-disabled {
    opacity: .4;
    pointer-events: none;
  }
}

@mixin card-carousel-swiper {
  //@include idangerous-swiper-base;
  //@include idangerous-swiper-arrows;
  //@include idangerous-swiper-pagination;

  //@include mobile {
  .swiper-container {
    //padding-bottom: 40px;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;

    .card {
      height: 100%;
    }
  }

  .swiper-pagination-bullet {
    background-color: $color-white;
    height: 10px;
    margin-right: 10px;
    opacity: 1;
    transition: background-color $easing-change;
    width: 10px;

    &:hover,
    &:focus {
      background-color: $color-grey-dark;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $color-green-light;

    &:hover,
    &:focus {
      background-color: $color-green-dark;
    }
  }
  //}

  .swiper-pagination {
    display: none;
  }
}
