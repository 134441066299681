
.menu-overlay {
  background-color: $color-white;
  bottom: 0;
  color: $color-black;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-menu-overlay;

  html.has-js & {
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity $easing-change;
  }

  html.has-js.overlay-visible & {
    opacity: 1;
    pointer-events: auto;
  }

  html:not(.has-js).overlay-visible & {
    display: block;
  }

  .menu-overlay__grid,
  .menu-overlay__wrapper {
    height: 100%;
  }

  .menu-overlay__grid {
    @include grid-container;

    max-width: 100%;
  }

  .menu-overlay__wrapper {
    display: flex;
    flex-direction: column;
    grid-column: column 1 / span 12;
    position: relative;
  }

  .menu-overlay__menu-wrapper {
    display: flex;
    flex-direction: row;
    grid-column: column 1 / span 12;
    height: 70px;
    padding-right: 20px;
  }

  .menu-overlay__button {
    align-self: center;
    line-height: 40px;
    margin-left: auto;
    text-transform: uppercase;
  }

  .menu-overlay__scroll-container {
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 100px;
    width: 100%;
  }

  .menu-overlay__scroll-object {
    //padding: 20px;
    height: 100%;
  }

  .link {
    display: block;
    line-height: 30px;
  }

  .icon-button__icon {
    display: block;
    height: calc(var(--button-line-height) * 2);
    width: calc(var(--button-line-height) * 2);
  }

  .icon-button .icon-button__title {
    display: inline-block;
    left: -150%;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
  }

  .button--close {
    color: $color-black;
    height: 40px;
    margin-left: 0;
    overflow: unset;
    padding: 0;
    right: 0;
    width: 40px;

    &:focus {
      color: $color-black;
    }

    .icon-button__icon {
      display: block;
      height: calc(var(--button-line-height) * 2);
      width: calc(var(--button-line-height) * 2);
    }

    @include landscape {
      margin-left: 5px;
      right: 0;
      top: 13px;
    }
  }

  .menu-overlay__menu-items {
    flex-grow: 1;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    .link {
      padding: 20px 0;
    }

    .link:first-of-type {
      @include ticket-theme-content using ($color, $text-color) {
        .link__span {
          background-color: $color;
          color: $text-color;
          padding: 10px 20px;
        }
      }
    }

    .link__span {
      height: 40px;
      line-height: 40px;
    }
  }

  .menu-overlay__languages {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;

    .link {
      min-height: 40px;
      min-width: 40px;
      text-align: center;
    }
  }

  .menu-overlay__links {
    margin-top: 30px;
  }

  @include mobile-only {
    .menu-overlay__logo .logo:focus {
      outline: 0;
    }
  }
}
