
:root {
  --heading-6-font-size: 16px;
  --heading-6-font-stack: #{$maisonneue-font-stack};
  --heading-6-font-style: normal;
  --heading-6-font-variant: normal;
  --heading-6-font-weight: #{get-maisonneue-weight(book)};
  --heading-6-letter-spacing: 0;
  --heading-6-line-height: 1.45em;
  --heading-6-margin-bottom: .5em;
  --heading-6-margin-top: 2em;

  @include portrait {
    --heading-6-font-size: 17px;
    --heading-6-line-height: 1.4em;
  }

  @include landscape {
    --heading-6-font-size: 18px;
    --heading-6-line-height: 1.35em;
  }
}
