.card-list {
  position: relative;

  .card-list__grid {
    @include grid-container;
  }

  .card-list__wrapper {
    grid-column: column 1 / span 12;
  }

  .card-list__title {
    @include typography(heading-1);

    background-color: $color-elephant;
    font-size: 3em;
    line-height: .9em;
    padding: 80px 20px 20px;

    @include landscape {
      font-size: 4em;
      line-height: .8em;
      padding: 80px 20px 20px;
    }

    @include notebook {
      font-size: 7em;
      line-height: .8em;
      padding: 200px 40px 40px;
    }

    @include desktop {
      font-size: 7.5em;
      line-height: .9em;
    }
  }

  .card__label {
    @include typography(paragraph);

    flex: 1 0 auto;
    margin-bottom: auto;
    text-transform: uppercase;
  }

  .card__title {
    @include typography(heading-1);

    font-size: 2em;

    @include notebook {
      font-size: 3em;
    }

    @include desktop {
      font-size: 4em;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    min-height: 66vh;
    width: 100%;

    @include landscape {
      display: flex;
      flex-direction: row;
      min-height: 50vh;
      width: 100%;
    }

    .card__image {
      order: 1;
    }

    .card__content {
      order: 2;
    }

    @include landscape {
      .card__image {
        order: 2;
      }

      .card__content {
        order: 1;
      }
    }
  }

  .card__image {
    flex: unset;
    height: 33vh;
    overflow: hidden;
    position: relative;

    @include landscape {
      flex: 1 1 67%;
      height: auto;
      min-height: initial;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 43.75%;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .card__content {
    background-color: $color-grey-extralight;
    display: flex;
    flex: 1 1 33%;
    flex-direction: column;
    padding: 20px;
    position: relative;

    @include notebook {
      padding: 30px;
    }
  }

  .card__text {
    @include desktop {
      font-size: 1.2em;
    }
  }

  .card__title,
  .card__text {
    flex: 0 1 auto;
    margin-bottom: 0;
  }

  .card-list__pagination {
    background-color: $color-elephant;
    padding: 0 20px;
    text-align: center;

    .link {
      padding: 60px 0px;
    }

    .link__icon {
      height: 34px;
      padding: 0;
      width: 34px;
    }
  }

  &.card-list-zigzag .card {
    &:nth-of-type(odd) .card__image {
      order: 1;
    }

    &:nth-of-type(odd) .card__content {
      order: 2;
    }

    &:nth-of-type(even) .card__image {
      order: 2;
    }

    &:nth-of-type(even) .card__content {
      order: 2;

      @include landscape {
        order: 1;
      }
    }
  }

  &.card-list-text .card {
    .card__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .card__rich-text {
      margin-top: 10px;
      padding-bottom: 30px;

      @include landscape {
        margin-top: 15px;
        max-width: 50%;
        padding-bottom: 0px;
      }
    }

    .card__link {
      @include landscape {
        position: absolute;
        right: 30px;
      }

      .link {
        float: right;

        @include landscape {
          float: initial;
        }
      }

      .link:hover .link__span,
      .link:focus .link__span {
        text-decoration: none;
      }

      .link .link__span {
        text-transform: uppercase;
      }

      .link .link__icon {
        height: 40px;
        vertical-align: middle;
        width: 40px;
      }
    }
  }
}
