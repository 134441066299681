
.logo {
  color: inherit;
  display: inline-block;
  height: 60px;
  line-height: 0;
  margin: 7px 0 3px 10px;
  position: relative;
  transition: color $easing-change;
  user-select: none;
  vertical-align: top;

  @include notebook {
    height: 70px;
    margin: 10px 0 0 30px;
  }

  .logo__svg {
    display: block;
    height: 100%;
    width: 100%;

    path {
      fill: currentColor;
      transition: fill $easing-change;
    }
  }
}
