.audio-fragment {
  &__player {
    display: flex;
    margin: 2rem 0 0;
    padding: 10px 0;
  }

  &__pause {
    display: inline-block;

    .audio-fragment__player.paused & {
      display: none;
    }
  }

  &__play {
    display: none;

    .audio-fragment__player.paused & {
      display: inline-block;
    }
  }

  .audio-fragment__player .audio-fragment__play {
    display: none;
  }

  .audio-fragment__player.paused .audio-fragment__play {
    display: inline-block;
  }

  &__progress-tracker {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 0 0 0 10px;

    span {
      font-size: 1rem;
      order: 1;
      text-align: center;
      width: 50px;

      .audio-fragment__player:not(.paused) & {
        &:last-child {
          display: none;
        }
      }

      .audio-fragment__player.paused & {
        &:first-child {
          display: none;
        }
      }
    }
  }

  &__tracker {
    cursor: pointer;
    flex-grow: 1;
    order: 2;

    &:hover .audio-fragment__track,
    &:hover .audio-fragment__line {
      height: 4px;
    }
  }

  &__track {
    background-color: $color-grey-dark;
    height: 1px;
    transition: height .2s;
  }

  &__line {
    background-color: $color-black;
    height: 1px;
    position: relative;
    transition: height .2s;
  }

  &__seeker {
    padding: 10px 0px;
  }
}
