
// Flexible webfont base file
// NOTE: When using this file as a base, rename all $*-font variables.

$prevent-font-face-declarations: false !default;

// Variable font settings
$ivartext-font-name: 'Ivar Text';
$ivartext-font-cleanname: 'ivar-text';
$ivartext-font-path: '../fonts/' + $ivartext-font-cleanname + '/';
$ivartext-font-stack: $ivartext-font-name, 'Time New Roman', serif;
$ivartext-font-feature-settings: 'kern' 1, 'calt' 1, 'liga' 1, 'frac' 0, 'dlig' 0;

// Weight names and their real values
// Defined here, since these names can vary per font family. Foundries get up the the weirdest things sometimes.

// Reference:
// - 100: thin, hairline
// - 200 extra light, ultra light
// - 300 light
// - 400 normal, regular, book
// - 500 medium
// - 600 semibold, demibold
// - 700 bold
// - 800 extrabold, ultrabold
// - 900 black, heavy

// Reminder: Font style is not supposed to be selected by name (ie: 'black-italic'),
// this is done automatically by setting font-style italic. However, it's still in the
// list in order for the @font-face to work
$ivartext-font-weights: (
  /*thin: (weight: 100, style: normal),
  thin-italic: (weight: 100, style: italic),
  extralight: (weight: 200, style: normal),
  extralight-italic: (weight: 200, style: italic),
  light: (weight: 300, style: normal),
  light-italic: (weight: 300, style: italic),
  regular: (weight: 400, style: normal),*/
  italic: (weight: 400, style: italic),
  /*medium: (weight: 500, style: normal),
  medium-italic: (weight: 500, style: italic),
  semibold: (weight: 600, style: normal),
  semibold-italic: (weight: 600, style: italic),
  bold: (weight: 700, style: normal),
  bold-italic: (weight: 700, style: italic),
  extrabold: (weight: 800, style: normal),
  extrabold-italic: (weight: 800, style: italic),
  black: (weight: 900, style: normal),
  black-italic: (weight: 900, style: italic),*/
);

// Automatically map weights
@each $weight, $properties in $ivartext-font-weights {
  $font-style: map-get($properties, style);
  $font-weight: map-get($properties, weight);

  @if not ($prevent-font-face-declarations) {
    @font-face {
      font-display: swap;
      font-family: $ivartext-font-name;
      font-style: $font-style;
      font-weight: $font-weight;
      src: url($ivartext-font-path + $ivartext-font-cleanname + '-' + $weight + '.woff2') format('woff2');
    }
  }
}

// Font mixin whereby you can use the real font weights as displayed in Sketch, such as 'book' or 'black'.
@mixin font-ivartext($font-weight: null) {
  // Set the basics
  font-family: $ivartext-font-stack;
  font-feature-settings: $ivartext-font-feature-settings;
  font-size-adjust: initial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @if ($font-weight) {
    font-weight: get-ivartext-weight($font-weight);
  }
}

// Given the weight name, get the real weight value. For example: 'black' returns 900.
@function get-ivartext-weight($font-weight) {
  // Convert possible 'normal' font-weight strings to 'regular', which is the font's actual weight name
  @if not $font-weight or $font-weight == 'normal' or $font-weight == 'roman' {
    $font-weight: 'regular';
  }

  // Look up the actual weight
  @if not (map-get($ivartext-font-weights, $font-weight)) {
    @error 'Variant "' + $font-weight + '" not found in "' + $ivartext-font-name + '" definition.';
  }

  $mapped-weight: map-get($ivartext-font-weights, $font-weight);
  $mapped-value-weight: map-get($mapped-weight, 'weight');

  @if ($mapped-value-weight) {
    $font-weight: $mapped-value-weight;
  }

  @return $font-weight;
}
