
:root {
  --field-label-font-size: 14px;
  --field-label-font-stack: #{$maisonneue-font-stack};
  --field-label-font-style: normal;
  --field-label-font-variant: normal;
  --field-label-font-weight: #{get-maisonneue-weight(book)};
  --field-label-letter-spacing: 0;
  --field-label-line-height: 24px;
  --field-label-margin-bottom: 8px;
  --field-label-margin-top: 0;

  @include portrait {
    --field-label-font-size: 15px;
  }

  @include landscape {
    --field-label-font-size: 16px;
  }
}
