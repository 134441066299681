
// Layouts

:root {
  --layout-content: column 1 / span 12;
  --layout-full: column 1 / span 12;
  --layout-center: column 1 / span 12;

  @include landscape {
    --layout-content: column 1 / span 10;
    --layout-center: column 2 / span 10;
  }

  @include notebook {
    --layout-content: column 1 / span 8;
    --layout-center: column 3 / span 8;
  }
}
