
.form-row {
  @include adaptive-margin(var(--grid-gutter));

  margin-left: calc(var(--grid-gutter) * -.5);
  margin-right: calc(var(--grid-gutter) * -.5);

  > div {
    margin: 0 calc(var(--grid-gutter) / 2);
  }

  &.form-row--footer {
    @include adaptive-margin(calc(var(--margin) / 2), calc(var(--margin) / 2));

    margin-left: 0;
    margin-right: 0;

    .button + .button {
      margin-left: 10px;
    }
  }

  @include breakpoints(mobile-only, portrait-only) {
    > div + div {
      margin-top: var(--grid-gutter);
    }
  }

  @include mobile-only {
    &.form-row--footer {
      .button {
        width: 100%;
      }

      .button + .button {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  @include portrait {
    display: flex;

    > div {
      flex: 1 1 50%;
    }
  }
}
