
.styleguide-typography {
  margin: var(--margin) 0;
  position: relative;

  .styleguide-typography__grid {
    @include grid-container;
  }

  .styleguide-typography__wrapper {
    grid-column: var(--layout-full);
    margin-right: auto;
    max-width: 32em;
  }

  hr {
    border-bottom: 2px dashed currentColor;
    height: 0;
    width: 100%;
  }

  h1 {
    @include typography(heading-1, true, true);
  }

  h2 {
    @include typography(heading-2, true, true);
  }

  h3 {
    @include typography(heading-3, true, true);
  }

  h4 {
    @include typography(heading-4, true, true);
  }

  h5 {
    @include typography(heading-5, true, true);
  }

  h6 {
    @include typography(heading-6, true, true);
  }

  p {
    @include typography(paragraph, true, true);
  }

  .styleguide-typography__introduction {
    @include typography(introduction, true, true);
  }

  .styleguide-typography__caption {
    @include typography(caption, true, true);
  }

  .styleguide-typography__field-label {
    @include typography(field-label, true, true);
  }

  .styleguide-typography__button {
    @include typography(button, true, true);
  }
}
