
:root {
  --richtext-heading-2-font-size: 24px;
  --richtext-heading-2-font-stack: #{$ivardisplay-font-stack};
  --richtext-heading-2-font-style: normal;
  --richtext-heading-2-font-variant: normal;
  --richtext-heading-2-font-weight: #{get-ivardisplay-weight(regular)};
  --richtext-heading-2-letter-spacing: -.005em;
  --richtext-heading-2-line-height: 1.25em;
  --richtext-heading-2-margin-bottom: 15px;
  --richtext-heading-2-margin-top: 1em;

  @include portrait {
    --richtext-heading-2-font-size: 27px;
    --richtext-heading-2-line-height: 1.2em;
  }

  @include landscape {
    --richtext-heading-2-font-size: 54px;
    --richtext-heading-2-line-height: 1.15em;
    --richtext-heading-2-margin-bottom: 20px;
  }

  @include desktop {
    --richtext-heading-2-font-size: 60px;
  }
}
