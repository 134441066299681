
:root {
  --caption-font-size: 14px;
  --caption-font-stack: #{$maisonneue-font-stack};
  --caption-font-style: normal;
  --caption-font-variant: normal;
  --caption-font-weight: #{get-maisonneue-weight(book)};
  --caption-letter-spacing: 0;
  --caption-line-height: 24px;
  --caption-margin-bottom: 0;
  --caption-margin-top: 8px;

  @include portrait {
    //
  }

  @include landscape {
    //
  }
}
