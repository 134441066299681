
:root {
  --richtext-heading-5-font-size: 16px;
  --richtext-heading-5-font-stack: #{$maisonneue-font-stack};
  --richtext-heading-5-font-style: normal;
  --richtext-heading-5-font-variant: normal;
  --richtext-heading-5-font-weight: #{get-maisonneue-weight(medium)};
  --richtext-heading-5-letter-spacing: 0;
  --richtext-heading-5-line-height: 1.4em;
  --richtext-heading-5-margin-bottom: .5em;
  --richtext-heading-5-margin-top: 1.75em;

  @include portrait {
    --richtext-heading-5-font-size: 16px;
    --richtext-heading-5-line-height: 1.35em;
  }

  @include landscape {
    --richtext-heading-5-font-size: 16px;
    --richtext-heading-5-line-height: 1.3em;
  }

  @include desktop {
    --richtext-heading-5-font-size: 18px;
  }
}
