
.card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  @include theme-content using ($color, $text-color) {
    color: $text-color;

    .card__content {
      background-color: $color;
    }
  }

  .card__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .card__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;
    position: relative;
    transition: background-color $easing-change, color $easing-change;

    @include notebook {
      padding: 30px;
    }
  }

  .card__title {
    @include typography(heading-6);

    background-color: transparent;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    cursor: pointer;
    display: inline;
    margin-bottom: var(--heading-6-margin-bottom);
    text-decoration: none;
    transition: background-size .2s linear;
  }

  &:hover .card__title,
  &:focus .card__title {
    background-size: 100% 1px;
  }

  .picture__image {
    transform: scale(1.15) translateX(1%);
    transition: transform 2s;
  }

  &:hover .picture__image,
  &:focus .picture__image {
    transform: scale(1.15) translateX(-1%);
    transition: transform 2s;
  }

  .card__text {
    @include typography(paragraph);

    flex: 1 1 auto;
  }
}
