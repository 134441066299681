
.styleguide-card {
  color: inherit;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  width: 100%;

  .styleguide-card__content {
    background-color: $color-grey-extralight;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 200px;
    padding: 20px;
    position: relative;
    transition: background-color $easing-change, color $easing-change;
  }

  .styleguide-card__title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  .styleguide-card__text {
    @include typography(paragraph);

    flex: 1 1 auto;
  }
}

.styleguide-card--color-pink {
  .styleguide-card__content {
    border-left: 100px solid $color-pink;
  }
}

.styleguide-card--color-blue {
  .styleguide-card__content {
    border-left: 100px solid $color-blue;
  }
}

.styleguide-card--color-green {
  .styleguide-card__content {
    border-left: 100px solid $color-green;
  }
}

.styleguide-card--color-pink-dark {
  .styleguide-card__content {
    border-left: 100px solid $color-pink-dark;
  }
}

.styleguide-card--color-blue-dark {
  .styleguide-card__content {
    border-left: 100px solid $color-blue-dark;
  }
}

.styleguide-card--color-green-dark {
  .styleguide-card__content {
    border-left: 100px solid $color-green-dark;
  }
}

.styleguide-card--color-grey-extradark {
  .styleguide-card__content {
    border-left: 100px solid $color-grey-extradark;
  }
}

.styleguide-card--color-grey-dark {
  .styleguide-card__content {
    border-left: 100px solid $color-grey-dark;
  }
}

.styleguide-card--color-grey-medium {
  .styleguide-card__content {
    border-left: 100px solid $color-grey-medium;
  }
}

.styleguide-card--color-grey-light {
  .styleguide-card__content {
    border-left: 100px solid $color-grey-light;
  }
}

.styleguide-card--color-grey-extralight {
  .styleguide-card__content {
    border-left: 100px solid $color-grey-extralight;
  }
}

.styleguide-card--color-white {
  .styleguide-card__content {
    border-left: 100px solid $color-white;
  }
}

.styleguide-card--color-black {
  .styleguide-card__content {
    border-left: 100px solid $color-black;
  }
}

.styleguide-card--color-error {
  .styleguide-card__content {
    border-left: 100px solid $color-error;
  }
}
