
.card-grid {
  position: relative;

  @include theme-content using ($color, $text-color) {
    .card__content {
      background-color: $color;
      color: $text-color;
    }
  }

  .card-grid__grid {
    @include grid-container;
  }

  .card-grid__wrapper {
    grid-column: column 1 / span 12;
  }

  .card-grid__title {
    @include typography(heading-1);
  }

  .card-grid__cards {
    display: flex;
    flex-direction: column;

    @include landscape {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .card {
      flex: 1 1 100%;
      justify-self: center;

      @include landscape {
        flex: 1 1 50%;
        min-height: 100vh;
      }
    }

    .card__label {
      @include typography(paragraph);

      flex: 1 0 auto;
      margin-bottom: auto;
      text-transform: uppercase;
    }

    .card:nth-of-type(even) .card__content {
      order: 2;
    }

    .card:nth-of-type(even) .card__image {
      order: 1;
    }

    .card__image {
      height: 33vh;
    }

    .card__content {
      min-height: 33vh;
    }

    @include landscape {
      .card__image {
        height: 66vh;
      }

      .card:nth-of-type(4n+2),
      .card:nth-of-type(4n+3) {
        .card__content {
          order: 1;
        }

        .card__image {
          order: 2;
        }
      }
    }

    .card__title {
      @include typography(heading-1);

      font-size: 2em;

      @include notebook {
        font-size: 3em;
      }

      @include desktop {
        font-size: 4em;
      }
    }

    .card__text {
      @include desktop {
        font-size: 1.2em;
      }
    }

    .card__title,
    .card__text {
      flex: 0 1 auto;
      margin-bottom: 0;
    }
  }

  .card-grid__pagination {
    text-align: center;
  }
}
