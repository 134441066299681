
// Grid variables
//
// You can add or remove breakpoints at will,
// but be sure to add/remove/modify the relevant mixins as well!

:root {
  // Breakpoints
  --breakpoint-mobile: #{$mobile};
  --breakpoint-portrait: #{$portrait};
  --breakpoint-landscape: #{$landscape};
  --breakpoint-notebook: #{$notebook};
  --breakpoint-desktop: #{$desktop};

  // Breakpoint reference (for javascript)
  --breakpoint: var(--breakpoint-mobile);

  // Document constraints
  --document-min-width: #{$website-min-width};
  --document-max-width: #{$website-max-width};

  // Helper variables
  --full-grid-column-width: calc((100% - (var(--grid-margin) * 2) - (var(--grid-gutter) * (var(--grid-columns) + 1))) / var(--grid-columns));

  @include mobile {
    --beat: 10px;
    --grid-columns: 12;
    --grid-debug-color: #f06;
    --grid-gutter: 20px;
    --grid-margin: 20px;
    --grid-max-width: 100%;
    --grid-spacing: 20px;
    --margin: 20px;
  }

  @include portrait {
    --breakpoint: var(--breakpoint-portrait);
    --grid-columns: 12;
    --grid-debug-color: #0cf;
    --grid-gutter: 20px;
    --grid-margin: 20px;
    --grid-spacing: 20px;
    --margin: 20px;
  }

  @include landscape {
    --breakpoint: var(--breakpoint-landcape);
    --grid-columns: 12;
    --grid-debug-color: #0f9;
    --grid-gutter: 20px;
    --grid-margin: 20px;
    --grid-spacing: 20px;
    --margin: 20px;
  }

  @include notebook {
    --breakpoint: var(--breakpoint-notebook);
    --grid-columns: 12;
    --grid-debug-color: #c3f;
    --grid-gutter: 20px;
    --grid-margin: 20px;
    --grid-spacing: 20px;
    --grid-max-width: 100%;
    --margin: 20px;
  }

  @include desktop {
    --breakpoint: var(--breakpoint-desktop);
    --grid-columns: 12;
    --grid-debug-color: #f06;
    --grid-gutter: 20px;
    --grid-margin: 20px;
    --grid-spacing: 20px;
    --grid-max-width: 1920px;
    --margin: 20px;
  }

  // overlay settings
  //--repeating-width: calc(100% / var(--grid-columns));
  //--column-width: calc((100% / var(--grid-columns)) - var(--grid-gutter));
  //--background-width: calc(100% + var(--grid-gutter));
  //--background-columns: repeating-linear-gradient(to right, var(--grid-debug-color), var(--grid-debug-color) var(--column-width), transparent var(--column-width), transparent var(--repeating-width));
}

@mixin grid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))', $with-spacing: false, $with-micrio-strip: true) {
  display: grid;
  grid-gap: if($with-spacing, var(--grid-spacing), 0) var(--grid-gutter);
  grid-template-columns: unquote($columns);
  margin-right: auto;

  @include notebook {
    @if $with-micrio-strip {
      max-width: calc(var(--grid-max-width) - (var(--grid-gutter) * 4));
    }
  }

  @include desktop {
    @if $with-micrio-strip {
      max-width: calc(var(--grid-max-width) - (var(--grid-gutter) * 4));
    }
  }
}

@mixin subgrid-container($columns: auto) {
  display: grid;
  grid-gap: var(--grid-spacing) var(--grid-gutter);
  grid-template-columns: $columns;
}
