
:root {
  --swiper-pagination-font-size: 32px;
  --swiper-pagination-font-stack: #{$maisonneue-font-stack};
  --swiper-pagination-font-style: normal;
  --swiper-pagination-font-variant: normal;
  --swiper-pagination-font-weight: #{get-maisonneue-weight(book)};
  --swiper-pagination-letter-spacing: -.8px;
  --swiper-pagination-line-height: 1.25em;
  --swiper-pagination-margin-bottom: 0;
  --swiper-pagination-margin-top: 0;
}
