
body:not(.micrio-active):not(.micrio-deactivating) {
  .micrio__overlay {
    pointer-events: none;
    visibility: hidden;
  }

  micrio-markers {
    opacity: 0;
    transition: opacity 1s;
  }
}

body.micrio-active {
  .micrio__strip {
    display: none;
  }

  micrio-markers {
    opacity: 1;
    transition: opacity 1s;
  }
}

.micrio__canvas:hover {
  cursor: pointer;
}

.micrio__strip {
  @include notebook {
    display: block;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: calc(4 * var(--grid-gutter));
    z-index: 4;
  }

  @include desktop {
    display: block;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: calc(100% - (var(--breakpoint-desktop) - (4 * var(--grid-gutter))));
    z-index: 4;
  }
}

.link.micrio__close-button {
  left: 20px;
  pointer-events: all;
  position: fixed;
  top: 20px;

  @include back-link;
}

.micrio__overlay {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.micrio__control-buttons {
  pointer-events: all;
  position: absolute;
  right: 20px;
  top: 20px;
}

figure.micrio {
  micrio-interface {
    aside {
      display: none;
    }
  }
}

// MicrioState

.micrio-state__templates {
  display: none;
}

.micrio-state__top-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px;
}

.micrio-state__content-controls {
  display: flex;
  flex-direction: row;
  margin: 30px 0 50px;
}

.micrio-tour-teaser__content,
.micrio-state__content {
  @include typography(heading-4);

  h3 ~ p {
    @include typography(paragraph);
  }
}

.micrio-state__content {
  margin-bottom: 20px;
}

.micrio-state__content-detail {
  @include typography(paragraph);
}

.micrio-state__tour-state {
  margin-bottom: 50px;
}

.micrio-state__tour-title {
  @include typography(button);

  text-transform: capitalize;
}

.micrio-state__tour-progress {
  @include typography(swiper-captions);
}

.micrio-state__tour-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 30px;
}

.micrio-state__detail-title {
  margin-bottom: 50px;

  @include typography(richtext-heading-2);
}

.micrio-state__detail-body {
  @include typography(richtext-heading-4);

  margin-bottom: 30px;
}

.micrio-state__detail-body-secondary {
  @include typography(paragraph);

  margin-bottom: 30px;
}

.micrio-state:not(.micrio-state--active):not(.micrio-state--deactivating) {
  pointer-events: none;
  visibility: hidden;
}

// Detail state
.micrio-state--detail {
  //position: absolute;
  //right: 20px;
  //top: 0;
  //width: 400px;
  height: 100%;
  //background: white;
  //max-height: 100%;
  //overflow: auto;
  pointer-events: all;
}

// Intro state
.micrio-state--intro {
  //background: white;
  //width: 400px;
  transition: transform 400ms ease-out;

  &.micrio-state--active {
    pointer-events: all;
  }

  &:not(.micrio-state--active) {
    transform: translateX(calc(100% + 80px));
  }
}

// Existing style

div.micrio {
  //a {
  //  color: inherit;
  //  text-decoration: underline;
  //  text-decoration-color: #0674ba;
  //}
  //
  //a:hover {
  //  text-decoration: none;
  //}

  a:not([href]) {
    cursor: pointer;
  }

  h2 {
    @include typography(richtext-heading-2);

    margin-bottom: 20px;
  }

  .loader {
    display: inline-block;
    height: 80px;
    position: relative;
    width: 80px;
  }

  .loader div {
    animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
    border: 8px solid #0674ba;
    border-color: #0674ba transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 64px;
    margin: 8px;
    position: absolute;
    width: 64px;
  }

  .loader div:nth-child(1) {
    animation-delay: -.45s;
  }

  .loader div:nth-child(2) {
    animation-delay: -.3s;
  }

  .loader div:nth-child(3) {
    animation-delay: -.15s;
  }

  @-moz-keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  figure.micrio {
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  figure.micrio div.micrio-container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  figure.micrio div.micrio-container.loaded {
    background: #000;
  }

  figure.micrio div.micrio-container > canvas {
    -ms-content-zooming: none;
    display: block;
    height: 100%;
    -ms-touch-action: none;
    touch-action: none;
    transition: opacity .25s ease;
    user-select: none;
    width: 100%;
  }

  figure.micrio div.micrio-container > canvas.minimap {
    bottom: 30px;
    left: 30px;
    position: absolute;
    transition: opacity .5s ease;
  }

  figure.micrio div.micrio-container > canvas.minimap.micrio-hide:not(:hover) {
    opacity: 0;
    pointer-events: none;
  }

  figure.micrio div.micrio-container.hooked > canvas {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -ms-grab;
    cursor: grab;
  }

  figure.micrio div.micrio-container.hooked.dragging > canvas {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
  }

  figure.micrio a[slot='marker'] {
    background: $color-blue url('data:image/svg+xml,%3Csvg%20width%3D%229px%22%20height%3D%229px%22%20viewBox%3D%220%200%209%209%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%20%20%20%20%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22%23eeeeee%22%20fill-rule%3D%22evenodd%22%3E%20%20%20%20%20%20%20%20%3Cpolygon%20points%3D%225.282%208.884%205.282%205.302%208.684%205.302%208.684%203.7%205.282%203.7%205.282%200.118%203.518%200.118%203.518%203.7%200.116%203.7%200.116%205.302%203.518%205.302%203.518%208.884%22%3E%3C%2Fpolygon%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E') center center no-repeat;
    border: none;
    border-radius: 16px;
    //box-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    display: block;
    height: 32px;
    left: 0;
    line-height: 32px;
    margin: -16px 0 0 -16px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 0;
    transition: opacity .25s ease;
    width: 32px;
    z-index: 1;
  }

  figure.micrio a[slot='marker']:hover {
    background-color: $color-peach;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%229px%22%20height%3D%229px%22%20viewBox%3D%220%200%209%209%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%20%20%20%20%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22%23000000%22%20fill-rule%3D%22evenodd%22%3E%20%20%20%20%20%20%20%20%3Cpolygon%20points%3D%225.282%208.884%205.282%205.302%208.684%205.302%208.684%203.7%205.282%203.7%205.282%200.118%203.518%200.118%203.518%203.7%200.116%203.7%200.116%205.302%203.518%205.302%203.518%208.884%22%3E%3C%2Fpolygon%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
  }

  figure.micrio a[slot='marker'].active {
    background-color: $color-peach;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%229px%22%20height%3D%229px%22%20viewBox%3D%220%200%209%209%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%20%20%20%20%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22%23000000%22%20fill-rule%3D%22evenodd%22%3E%20%20%20%20%20%20%20%20%3Cpolygon%20points%3D%225.282%208.884%205.282%205.302%208.684%205.302%208.684%203.7%205.282%203.7%205.282%200.118%203.518%200.118%203.518%203.7%200.116%203.7%200.116%205.302%203.518%205.302%203.518%208.884%22%3E%3C%2Fpolygon%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
  }

  figure.micrio a[slot='marker']:not([style]) {
    display: none;
  }

  figure.micrio[no-markers] a[slot='marker'],
  figure.micrio[no-markers] div.micrio-container > canvas.minimap {
    opacity: 0;
    pointer-events: none;
  }

  figure.micrio[no-markers] button.micrio-button.audio {
    display: none;
  }

  button.micrio-button.audio {
    background-color: #fff;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2225px%22%20height%3D%2222px%22%20viewBox%3D%220%200%2025%2022%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20id%3D%22design%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%2213-audio%22%20transform%3D%22translate(-1331.000000%2C%20-730.000000)%22%20fill%3D%22%230674ba%22%3E%3Cg%20id%3D%22Group-8%22%20transform%3D%22translate(1331.000000%2C%20730.000000)%22%3E%3Cpolygon%20id%3D%22Fill-1%22%20points%3D%2213.7591219%200.000104832004%205.16289756%206.67239979%20-7.86240065e-05%206.41031978%20-7.86240065e-05%209.58174997%20-7.86240065e-05%2014.8241364%205.16289756%2014.5620564%2013.7591219%2021.2343513%2013.7591219%209.58174997%2013.7591219%206.69965611%22%3E%3C%2Fpolygon%3E%3Cpath%20d%3D%22M17.9524282%2C10.6045172%20C17.9524282%2C11.6200772%2017.5876128%2C12.5488888%2016.9214055%2C13.2984376%20L18.6697412%2C15.0467734%20C19.7982577%2C13.8425157%2020.4159803%2C12.2676769%2020.4159803%2C10.5783092%20C20.3918689%2C8.98171779%2019.7856779%2C7.43046621%2018.6828452%2C6.21179417%20L16.9518067%2C7.94283263%20C17.5994064%2C8.6866157%2017.9524282%2C9.60363365%2017.9524282%2C10.6045172%22%20id%3D%22Fill-2%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M20.1207472%2C4.77360391%20C21.5928506%2C6.35473261%2022.4341274%2C8.45006228%2022.4341274%2C10.63083%20C22.4341274%2C12.8139565%2021.591016%2C14.9111207%2020.1160297%2C16.4930357%20L21.8481165%2C18.2248604%20C23.7778116%2C16.1816846%2024.8714715%2C13.4715153%2024.8714715%2C10.63083%20C24.8950587%2C7.79066898%2023.8045438%2C5.08128584%2021.856241%2C3.03811009%20L20.1207472%2C4.77360391%20Z%22%20id%3D%22Fill-4%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    position: absolute;
    right: 0px;
    top: 0px;
    transition: none;
    z-index: 2;
  }

  .audio-player {
    background: $color-white;
    display: flex;
    margin: 2rem 0 0;
    padding: 10px;
  }

  .audio-player .pause {
    display: inline-block;
  }

  .audio-player .play {
    display: none;
  }

  .audio-player.paused .pause {
    display: none;
  }

  .audio-player.paused .play {
    display: inline-block;
  }

  //.audio-player button {
  //  color: #fff;
  //  display: inline-block;
  //  flex-basis: 35px;
  //  height: 35px;
  //  position: relative;
  //  vertical-align: bottom;
  //  width: 35px;
  //}

  .audio-player button:focus {
    outline: 0;
  }

  .audio-player .progress-tracker {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 0 10px;
  }

  .audio-player .progress-tracker span {
    font-family: monospace;
    font-size: 1rem;
    order: 1;
    text-align: center;
    width: 50px;
  }

  .audio-player:not(.paused) .progress-tracker span:last-child {
    display: none;
  }

  .audio-player.paused .progress-tracker span:first-child {
    display: none;
  }

  .audio-player .progress-tracker .tracker {
    cursor: pointer;
    flex-grow: 1;
    order: 2;
  }

  .audio-player .progress-tracker .tracker .track {
    background-color: #d8d8d8;
    height: 1px;
  }

  .audio-player .progress-tracker .tracker .line {
    background-color: $color-black;
    height: 1px;
    position: relative;
  }

  .audio-player .progress-tracker .tracker .seeker {
    padding: 10px 0px;
  }

  article {
    background: $color-peach;
    box-sizing: border-box;
    pointer-events: all;
    z-index: 2;
  }

  article.detail {
    margin: 0 0 0 auto;
    overflow-y: auto;
    padding: 20px 0 40px;
    position: relative;
    width: 480px;
  }

  .detail-content {
    padding: 0 30px;
  }

  article.detail figure img {
    cursor: pointer;
  }

  @media (max-width: 500px) {
    article.detail {
      bottom: 0;
      height: 42%;
      position: absolute;
      width: 100vw;
    }
  }

  article.detail > h2 {
    margin-right: 70px;
  }

  article figure {
    margin: 0 0 3em;
    padding: 0;
  }

  article figure img {
    width: 100%;
  }

  article figure iframe {
    height: 236px;
    max-width: 100%;
  }

  article figure figcaption {
    color: #999;
    font-size: .88rem;
    line-height: 1.5em;
    margin: 10px 0;
  }

  article ul.tours {
    list-style-type: none;
    margin: 0 -30px;
    padding: 0;
  }

  article ul.tours li {
    margin: 0;
    padding: 20px 30px;
  }

  article ul.tours li:nth-child(2n+1) {
    background-color: #f5f5f5;
  }

  article ul.tours li h3 {
    color: #000;
    margin-bottom: 0;
  }

  article ul.tours li h5 {
    margin-bottom: 1em;
  }

  article ul.tours li .micrio-button {
    margin: 0;
    padding-left: 0;
  }

  .welcome {
    background: $color-blue;
    color: $color-black;
    height: 100%;
    overflow-y: auto;
    padding: 20px 0 40px;
    position: fixed;
    right: 0;
    top: 0;
    width: 480px;
    z-index: 100;
  }

  @media (max-width: 500px) {
    .welcome {
      margin: 0;
      width: auto;
    }
  }

  .welcome .welcome-content {
    padding: 0 60px 30px 30px;
  }

  div.popover {
    background-color: transparent;
    display: none;
    height: 100%;
    left: 0;
    pointer-events: all;
    position: fixed;
    top: 0;
    transition: background-color .5s ease;
    width: 100%;
    z-index: 4;
  }

  div.popover .micrio-button {
    background-color: #fff;
  }

  div.popover[shown] {
    background: rgba(0, 0, 0, .5);
  }

  div.popover > .content {
    background: rgba(0, 0, 0, .5);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
    position: relative;
  }

  @media (max-width: 640px) {
    div.popover > .content {
      bottom: 12px;
      left: 12px;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }

  @media (min-width: 640px) {
    div.popover > .content {
      height: calc(100vh - 200px);
      margin: 55px auto;
      max-width: 1600px;
      min-width: 500px;
      width: 70vw;
    }
  }

  div.popover > .content > a.close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
  }

  div.popover > .content > button.micrio-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  div.popover > .content > button.micrio-button.arrow-left {
    left: 0;
  }

  div.popover > .content > button.micrio-button.arrow-right {
    right: 0;
  }

  div.popover > .content figure {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  div.popover > .content figure > canvas.minimap {
    bottom: 20px;
    left: auto;
    right: 20px;
    top: auto;
  }
}
