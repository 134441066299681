
.richtext {
  position: relative;
}

.richtext--regular {
  h1 {
    @include typography(richtext-heading-1, true, true);
  }

  h2 {
    @include typography(richtext-heading-2, true, true);
  }

  h3 {
    @include typography(richtext-heading-3, true, true);
  }

  h4 {
    @include typography(richtext-heading-4, true, true);
  }

  h5 {
    @include typography(richtext-heading-5, true, true);
  }

  h6 {
    @include typography(heading-6, true, true);
  }

  p,
  ul,
  ol {
    @include typography(paragraph, true, true, true);
  }

  p {
    @include enable-strong;
    @include enable-emphasized;
  }

  li {
    @include typography(paragraph, false, false);
    @include adaptive-margin(calc(var(--paragraph-margin-top) * .5), calc(var(--paragraph-margin-bottom) * .5));
  }

  p,
  li {
    a {
      &,
      &:link,
      &:active,
      &:visited {
        @include style-anchor-plain($inverted: false);

        color: inherit;
      }
    }
  }

  ul {
    @include style-list-base;
    @include style-list-unordered;
  }

  ol {
    @include style-list-base;
    @include style-list-ordered;
  }
}

.richtext--simple {
  p {
    @include typography(paragraph, true, true);
    @include enable-strong;
    @include enable-emphasized;
  }
}

.richtext--introduction {
  p {
    @include typography(introduction, true, true);
  }
}
