
.styleguide-wrapper {
  margin: var(--margin) 0;
  position: relative;

  .styleguide-wrapper__grid {
    @include grid-container;
  }

  .styleguide-wrapper__wrapper {
    @include clearfix;

    grid-column: var(--layout-full);
    margin-right: auto;

    > .button,
    > .link,
    > .timestamp {
      clear: left;
      float: left;
      margin-top: 1rem;
    }

    > .video {
      min-width: 320px;
    }
  }
}
