
.figure {
  position: relative;

  .picture {
    height: 100%;
    width: 100%;
  }

  .figure__caption {
    @include typography(caption, true, false);
  }
}
