
.timestamp {
  @include typography(caption);

  display: inline-flex;
  position: relative;
  text-decoration: none;
  vertical-align: bottom;

  .timestamp__span {
    display: inline-block;
    vertical-align: baseline;
  }
}
