.dropdown {
  position: absolute;
  right: -60px;
  top: 0;
}

.dropdown__main {
  background-color: $color-white;
  margin-top: 60px;
  padding: 20px;
}

.dropdown__items {
  display: flex;
  flex-direction: column;
  text-align: center;

  .link {
    margin: 0 60px;
  }
}
