
.mega-content {
  background-color: transparent;
  position: relative;

  @include theme-content using ($color, $text-color) {
    color: $text-color;

    .mega-content__wrapper {
      background-color: $color;
    }
  }

  .mega-content__wrapper {
    grid-column: column 1 / span 12;
    padding: 20px;

    @include notebook {
      padding: 40px;
    }
  }

  .mega-content__grid {
    @include grid-container;

    @include landscape {
      align-items: flex-end;
    }
  }

  .mega-content__title {
    @include typography(heading-1);

    font-size: 3em;
    line-height: .9em;
    padding-top: 60px;

    @include landscape {
      font-size: 4em;
      line-height: .8em;
    }

    @include notebook {
      font-size: 7em;
      line-height: .8em;
      padding-top: 100px;
    }
  }

  .mega-content__pre-title {
    @include typography(heading-4);

    text-transform: uppercase;
  }

  .mega-content__post-title {
    @include typography(heading-4);

    margin-top: calc(var(--paragraph-margin-top) / 2);
  }

  .mega-content__header {
    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .mega-content__introduction {
    @include grid-container;

    @include typography(heading-2);

    font-size: 1.3em;
    line-height: 1.3em;
    max-width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;

    @include notebook {
      font-size: 2.1em;
      line-height: 1.3em;
      max-width: 95%;
      padding-bottom: 60px;
    }

    @include desktop {
      font-size: 2.33em;
    }

    .richtext--introduction {
      grid-column: column 1 / span 12;
    }
  }

  .mega-content__image {
    .figure__caption {
      display: none;
    }
  }

  .mega-content__video {
    .video-embed .video-embed__container {
      height: 0;
      padding-bottom: 56.25%;
    }
  }

  .mega-content__audio,
  .mega-content__table {
    @include grid-container;

    max-width: 100%;

    .audio-fragment,
    .table {
      grid-column: column 1 / span 12;

      @include notebook {
        grid-column: column 5 / span 7;
      }
    }
  }

  .mega-content__richtext {
    @include grid-container;

    max-width: 100%;
    padding-bottom: 30px;
    padding-top: 60px;

    .richtext--regular {
      grid-column: column 1 / span 12;

      @include notebook {
        grid-column: column 5 / span 7;
      }
    }
  }

  .mega-content__footer {
    @include grid-container;

    max-width: 100%;
    padding-bottom: 60px;
  }

  .mega-content__links {
    display: flex;
    flex-direction: column;
    grid-column: column 1 / span 12;

    @include notebook {
      grid-column: column 5 / span 7;
    }
  }

  .mega-content__scroll-down {
    grid-column: 6;

    @include landscape {
      grid-column: 12;
    }
  }
}
