
// Base colors
$color-black: #000;
$color-white: #fff;

// Theme colors
$color-pink: #fd5ea2;
$color-blue: #4b82eb;
$color-peach: #ffe1c3;
$color-orange: #ff8228;
$color-red: #ff6e6e;
$color-mustard: #c8af5a;
$color-mustard-dark: #8c7d4b;
$color-viking: #50d2d7;
$color-turqouise: #50d7a5;
$color-green: #39e492;
$color-elephant: #8a8d8f;

// Darkened theme colors
$color-pink-dark: #f2026a;
$color-blue-dark: #11717e;
$color-green-dark: #16a25e;

// Greyscale colors

$color-grey-extradark: #3c3c3c;
$color-grey-dark: #696969;
$color-grey: #8a8d8f;
$color-grey-medium: #969696;
$color-grey-light: #c3c3c3;
$color-grey-mediumlight: #d8d8d8;

$color-grey-extralight: #fafafa;

// Functional colors
$color-error: #ff4735;

// Back-end color map
$themes: ('mine-shaft' $color-grey-extradark $color-white),
  ('oslo-gray' $color-grey $color-black),
  ('tasman' $color-grey-mediumlight $color-black),
  ('shadow' $color-mustard-dark $color-black),
  ('sundance' $color-mustard $color-black),
  ('negroni' $color-peach $color-black),
  ('bittersweet' $color-red $color-black),
  ('crusta' $color-orange $color-black),
  ('cornflower-blue' $color-blue $color-black),
  ('viking' $color-viking $color-black),
  ('shamrock' $color-turqouise $color-black);

$ticket-themes: ('mine-shaft' $color-grey-extradark $color-white),
  ('oslo-gray' $color-grey $color-white),
  ('tasman' $color-grey-mediumlight $color-black),
  ('shadow' $color-mustard-dark $color-white),
  ('sundance' $color-mustard $color-black),
  ('negroni' $color-peach $color-black),
  ('bittersweet' $color-red $color-white),
  ('crusta' $color-orange $color-white),
  ('cornflower-blue' $color-blue $color-white),
  ('viking' $color-viking $color-black),
  ('shamrock' $color-turqouise $color-black);

// Transparencies
$transparency-overlay: rgba($color-white, .8);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-hit-target: 100;
$z-index-form-field-button: 10;
$z-index-menu-overlay: 2048;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
:root {
  // None yet
  //--bezier-fancy: $bezier-fancy;
}
