
.table {
  max-height: 400px;
  max-width: 100%;
  overflow: auto;
  scroll-snap-type: x mandatory;

  @include theme-content using ($color, $text-color) {
    thead th,
    tbody th {
      background-color: $color;
    }
  }

  @media (min-width: 40em) {
    .table {
      order: 2;
    }
  }

  table {
    border: 0;
    border-collapse: collapse;
  }

  tbody tr:last-child th,
  tbody tr:last-child td {
    border-bottom: 0;
  }

  thead {
    position: relative;
    z-index: 1000;
  }

  th,
  td {
    @include typography(paragraph);

    background-clip: padding-box;
    margin: 0;
    min-width: 160px;
    padding: 10px;
    scroll-snap-align: start;
    text-align: left;
  }

  th {
    font-weight: 700;
  }

  thead th {
    background-clip: padding-box;
    border-top: 0;
    position: sticky;
    top: 0;
  }

  thead th.pin {
    border-left: 0;
    left: 0;
    z-index: 1001;
  }

  tbody {
    position: relative;
    z-index: 10;
  }

  tbody th {
    background-clip: padding-box;
    border-left: 0;
    left: 0;
    position: sticky;
  }
}
