.text-header {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  @include theme-content using ($color, $text-color) {
    color: $text-color;

    .text-header__wrapper {
      background-color: $color;
    }
  }

  .text-header__wrapper {
    grid-column: column 1 / span 12;
    padding: 20px;

    @include notebook {
      padding: 40px;
    }
  }

  .text-header__grid {
    @include grid-container;

    flex: 0 1 100%;

    @include landscape {
      align-items: flex-end;
    }
  }

  .text-header__button {
    color: $color-black;
    left: 20px;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
    z-index: 1;

    @include notebook {
      left: 40px;
      top: 40px;
    }

    .link__span {
      font-size: 18px;
      line-height: 34px;
    }

    .link__icon {
      height: 34px;
      padding: 0;
      width: 34px;
    }
  }

  .text-header__title {
    @include typography(heading-1);

    font-size: 3.5em;
    line-height: .9em;

    @include landscape {
      font-size: 6em;
      line-height: .9em;
    }

    @include notebook {
      font-size: 7em;
      line-height: .9em;
    }

    @include desktop {
      font-size: 12em;
      line-height: 1em;
    }
  }

  .text-header__pre-title {
    @include typography(heading-4);

    max-width: 28em;
    text-transform: uppercase;
  }

  .text-header__introduction {
    @include grid-container;
    @include typography(heading-2);

    font-size: 1.3em;
    line-height: 1.3em;
    margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    margin-top: calc(var(--paragraph-margin-top) / 2);
    max-width: 100%;

    @include landscape {
      font-size: 1.7em;
      line-height: 1.2em;
    }

    @include notebook {
      font-size: 2em;
      line-height: 1.3em;
      margin-bottom: 0;
    }

    @include desktop {
      font-size: 2.33em;
    }

    .richtext--introduction {
      grid-column: auto / span 11;
    }
  }

  .text-header__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;

    @include notebook {
      padding-top: 150px;
    }

    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .text-header__buttons {
    font-size: 0;

    .button {
      margin: 20px 20px 0 0;
    }
  }

  .text-header__scroll-down {
    grid-column: 6;

    @include landscape {
      grid-column: 12;
    }
  }
}
