
.footer-bar {
  background-color: $color-white;
  color: $color-black;
  padding: 60px 0;
  position: relative;

  @include notebook {
    padding: 100px 0 0;
  }

  .footer-bar__column-title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  .footer-bar__grid {
    @include grid-container;

    max-width: 100%;
  }

  .footer-bar__column {
    grid-column: column 1 / span 12;
    margin-bottom: 20px;
    padding: 20px 20px 0;

    @include landscape {
      grid-column: auto / span 4;
      margin-bottom: 0px;
    }

    &:nth-child(1) {
      @include landscape {
        grid-column: auto / span 6;
      }
    }

    &:nth-child(2) {
      @include landscape {
        grid-column: auto / span 4;
      }
    }

    &:nth-child(3) {
      order: 4;
      padding: 40px 20px 0;

      @include landscape {
        grid-column: auto / span 2;
        order: initial;
        padding: 20px 0 0;
      }
    }

    &:nth-child(4) {
      margin-bottom: 30px;

      @include landscape {
        grid-column: column 1 / span 12;
      }
    }

    .link-list--vertical {
      column-count: 2;

      .link-list__links {
        display: block;

        .link {
          display: block;
        }

        .link__span {
          font-size: 20px;

          @include landscape {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }

    .link-list--horizontal .link:not(:last-child)::after {
      content: '|';
      margin-left: 20px;
      margin-right: 20px;
    }

    .link-list--horizontal .link {
      margin-right: 0px;
    }
  }

  .footer-logo {
    color: $color-grey-extradark;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }

  .icon-button {
    background-color: transparent;
    color: $color-black;
  }

  .icon-button:hover,
  .icon-button:focus {
    background-color: transparent;
    color: $color-grey;
  }
}
