
.mega-header {
  background-color: transparent;
  color: transparent;
  color: $color-black;
  display: flex;
  flex-direction: row;
  position: relative;

  @include theme-content using ($color, $text-color) {
    .mega-header__wrapper {
      background-color: $color;
      color: $text-color;
    }
  }

  .mega-header__button {
    color: $color-black;
    left: 10px;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
    z-index: 1;

    @include notebook {
      left: 30px;
      top: 30px;
    }

    .link__span {
      font-size: 18px;
      line-height: 34px;
    }

    .link__icon {
      height: 34px;
      padding: 0;
      width: 34px;
    }
  }

  .mega-header__image {
    background-color: $color-black;
    grid-column: column 1 / span 12;
    height: calc(100vh - 70px);

    @include notebook {
      height: calc(100vh - 80px);
    }

    .picture--loaded .picture__image {
      height: calc(100vh - 70px);

      @include notebook {
        height: calc(100vh - 80px);
      }
    }

    .mega-header__button {
      @include back-link;

      left: 20px;
      position: absolute;
      top: 20px;

      @include notebook {
        left: 40px;
        top: 40px;
      }
    }
  }

  &.mega-header--regular .mega-header__image {
    height: calc(50vh - 20px);

    @include notebook {
      height: calc(50vh - 30px);
    }

    .picture--loaded .picture__image {
      height: calc(50vh - 20px);

      @include notebook {
        height: calc(50vh - 30px);
      }
    }
  }

  .mega-header__wrapper {
    grid-column: column 1 / span 12;
    padding: 20px;

    @include notebook {
      padding: 40px;
    }
  }

  .mega-header__button ~ .mega-header__wrapper {
    padding: 100px 20px 20px;

    @include notebook {
      padding: 120px 40px 40px;
    }
  }

  .mega-header__grid {
    @include grid-container;

    align-items: flex-end;
    flex: 0 1 100%;
  }

  .mega-header__title {
    @include typography(heading-1);

    font-size: 4.2em;
    line-height: .9em;

    @include landscape {
      font-size: 5.5em;
      line-height: .9em;
    }

    @include notebook {
      font-size: 10em;
      line-height: 1em;
    }

    @include desktop {
      font-size: 12em;
      line-height: 1em;
    }
  }

  .mega-header__pre-title {
    @include typography(heading-4);

    margin-bottom: 80px;
    text-transform: uppercase;

    @include notebook {
      margin-bottom: 100px;
    }
  }

  .mega-header__post-title {
    @include typography(heading-3);

    @include landscape {
      font-size: 1.2em;
      line-height: 1.1em;
    }

    @include notebook {
      font-size: 1.5em;
      line-height: 1.1em;
    }

    @include desktop {
      font-size: 1.8em;
    }
  }

  .mega-header__header {
    display: flex;
    flex-direction: column;
    grid-column: auto / span 12;
    height: 100%;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .mega-header__introduction {
    @include typography(heading-2);

    @include grid-container;

    font-size: 1.3em;
    grid-column: auto / span 12;
    line-height: 1.3em;
    margin-bottom: 45px;
    margin-right: 0;
    margin-top: var(--paragraph-margin-top);
    max-width: 100%;

    @include landscape {
      font-size: 1.7em;
      line-height: 1.2em;
    }

    @include notebook {
      font-size: 2em;
      line-height: 1.3em;
      margin-bottom: 60px;
    }

    @include desktop {
      font-size: 2.33em;
    }

    .richtext--introduction {
      grid-column: auto / span 12;

      @include notebook {
        grid-column: auto / span 11;
      }
    }
  }

  .mega-header__text-wrapper {
    align-items: flex-end;
    flex: 0 1 100%;

    .mega-header__text {
      @include grid-container;

      margin-bottom: calc(var(--paragraph-margin-bottom) * 10);

      @include notebook {
        margin-bottom: calc(var(--paragraph-margin-bottom) * 4);
      }

      .richtext--regular {
        grid-column: column 1 / span 12;

        @include notebook {
          grid-column: column 5 / span 7;
        }
      }
    }
  }

  .mega-header__scroll-down {
    grid-column: 6;

    @include landscape {
      grid-column: 12;
    }
  }
}
