.hero-header {
  background-color: transparent;
  bottom: 0;
  color: $color-black;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 70px);
  position: relative;
  width: 100%;

  @include notebook {
    height: calc(100vh - 80px);
  }

  .hero-header__target {
    background: $color-white;
    inset: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    z-index: 0;
  }

  .hero-header__button {
    @include link-header-image;

    z-index: 2;
  }

  .hero-header__wrapper {
    background-color: $color-blue;
    color: $color-black;
    grid-column: column 1 / span 12;
    padding: 30px;
    position: relative;
    z-index: 1;
  }

  .hero-header__grid {
    @include grid-container;

    flex: 0 1 100%;

    @include mobile {
      align-items: flex-end;
      position: relative;
      width: 100%;
    }
  }

  .hero-header__header-link {
    .hero-header__title {
      background-color: transparent;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 90%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      cursor: pointer;
      transition: background-size .2s linear;
    }
  }

  .hero-header__title {
    display: inline;
    font-size: 2.5em;
    line-height: .9em;
    text-decoration: none;

    @include typography(heading-1);

    @include landscape {
      font-size: 4em;
      line-height: .9em;
    }

    @include notebook {
      font-size: 7em;
      line-height: .9em;
    }

    @include desktop {
      font-size: 8em;
      line-height: .9em;
    }
  }

  &:hover .hero-header__title,
  &:focus .hero-header__title {
    background-size: 100% 1px;
  }

  .hero-header__pre-title {
    @include typography(heading-4);

    max-width: 28em;
    text-transform: uppercase;
  }

  .hero-header__post-title {
    @include typography(heading-4);

    margin-top: calc(var(--paragraph-margin-top) / 2);
    max-width: 32em;
  }

  .hero-header__header {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-height: calc(33vh - 80px);

    @include desktop {
      justify-content: flex-end;
    }

    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .hero-header__introduction {
    max-width: 32em;
  }

  .hero-header__buttons {
    font-size: 0;

    .button {
      margin: 20px 20px 0 0;
    }
  }

  .hero-header__scroll-down {
    grid-column: 6;

    @include landscape {
      grid-column: 12;
    }
  }

  &.hero-header--has-target {
    cursor: pointer;
  }
}
