
// Mixins
@mixin theme-content {
  @each $name, $color, $text-color in $themes {
    &.theme--#{$name} {
      @content($color, $text-color);
    }
  }
}

@mixin ticket-theme-content {
  @each $name, $color, $text-color in $ticket-themes {
    &.theme--#{$name} {
      @content($color, $text-color);
    }
  }
}

@mixin link-shadow {
  background-color: rgba(0, 0, 0, .3);
}

@mixin back-link($color: $color-white, $shadow: true) {
  @if $shadow {
    @include link-shadow;
  }

  color: $color;
  padding: 6px 10px;
  text-transform: uppercase;
  z-index: 1;

  .link__span {
    font-size: 18px;
    line-height: 40px;
  }
}

@mixin link-header-image {
  @include back-link;

  left: 20px;
  position: absolute;
  top: 20px;

  @include notebook {
    left: 40px;
    top: 40px;
  }
}
