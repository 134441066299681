
.menu-bar {
  position: fixed;
  transition: transform .6s;
  width: 100%;
  z-index: 3;

  &.hide {
    transform: translateY(-100%);
  }

  .menu-bar__grid {
    background-color: $color-white;
    margin-top: 0px;
    padding-right: 20px;

    @include grid-container($with-micrio-strip: true);
  }

  .menu-bar__wrapper {
    display: flex;
    flex-direction: row;
    grid-column: column 1 / span 12;
  }

  .menu-bar__link {
    display: none;
    text-transform: uppercase;

    @include landscape {
      align-self: center;
      display: inline-block;
      flex-basis: 100%;
      text-align: right;

      .link__ticket {
        padding: 10px 20px;

        @include ticket-theme-content using ($color, $text-color) {
          background-color: $color;
          color: $text-color;
        }
      }

      > .link {
        margin-left: 20px;
      }
    }

    .link--default:hover .link__span {
      text-decoration: none;
    }
  }

  .menu-bar__button {
    align-self: center;
    margin-left: auto;

    @include landscape {
      display: none;
    }

    .icon-button--menu {
      height: 40px;
      overflow: unset;
      padding: 0;
      right: 0;
      width: 40px;
    }

    .icon-button__icon {
      display: block;
      height: calc(var(--button-line-height) * 2);
      width: calc(var(--button-line-height) * 2);
    }

    .icon-button__title {
      display: inline-block;
      left: -130%;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.micrio-active .menu-bar {
  transform: translateY(-100%);
}

body.page--home .menu-bar {
  background-color: $color-white;
}
