
.article-photo-carousel {
  margin-top: -1px;
  position: relative;

  @include theme-content using ($color, $text-color) {
    .article-photo-carousel__grid {
      background-color: $color;
      color: $text-color;
    }
  }

  .article-photo-carousel__grid {
    @include grid-container;
  }

  .article-photo-carousel__header {
    @include grid-container;

    max-width: none;

    //bottom: initial;
    //position: absolute;
    //right: initial;
    //top: -60px;
    width: 100%;
  }

  .article-photo-carousel__wrapper {
    grid-column: column 1 / span 12;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .article-photo-carousel__control {
    display: flex;
    flex-direction: column;
    grid-column: column 1 / span 12;
    position: relative;

    @include landscape {
      grid-column: column 1 / span 4;
    }
  }

  .article-photo-carousel__captions {
    align-items: flex-end;
    display: flex;
    grid-column: column 1 / span 12;
    margin: 10px var(--grid-gutter);

    @include typography(swiper-captions);

    @include notebook {
      grid-column: column 5 / span 7;
      margin: 0 0 15px 15px;
    }

    .swiper-slide {
      opacity: 0 !important;
      transition: opacity .2s;
    }

    .swiper-slide.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  .article-photo-carousel__photos {
    position: relative;
    width: 100%;

    .swiper-container {
      overflow: hidden;
    }

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
      padding: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    // override default
    background: none;
    display: inline-block;
    height: 38px;
    padding: 0;
    width: 38px;
  }

  .swiper-button-disabled {
    opacity: .4;
    pointer-events: none;
  }

  .swiper-pagination {
    @include typography(swiper-pagination);

    margin: 0 var(--grid-gutter);
    pointer-events: none;
    position: relative;
    text-align: center;
    transition: .3s opacity;
    z-index: 10;

    @include mobile-only {
      font-size: 1.4em;
      line-height: 36px;
      margin: 0;
      position: absolute;
      width: 100%;
    }

    @include portrait {
      text-align: left;
    }
  }

  .swiper-navigation {
    @include typography(heading-2);

    font-size: 1.3em;
    margin: 0 var(--grid-gutter);

    @include mobile-only {
      display: flex;
      justify-content: space-between;
    }

    @include landscape {
      font-size: 1.7em;
      line-height: 1.2em;
    }

    @include notebook {
      font-size: 2em;
      line-height: 1.3em;
    }
  }
}
