
.text-link {
  cursor: pointer;
  position: relative;

  .text-link__grid {
    @include grid-container;

    background-color: $color-grey-extradark;
    color: $color-red;
    height: 25vh;
    min-height: 160px;
    overflow: hidden;
    position: relative;

    @include landscape {
      height: 50vh;
      min-height: 350px;
    }

    @include desktop {
      max-height: 430px;
    }
  }

  .text-link__wrapper {
    display: inline-block;
    grid-column: column 1 / span 12;
    position: absolute;
    transition: transform .2s;
    white-space: nowrap;

    .link {
      @include typography(heading-1);

      font-size: 4.4em;
      line-height: 1em;

      @include landscape {
        font-size: 12.5em;
        line-height: 1em;
      }

      .link__span {
        text-transform: initial;
      }
    }

    .link__icon--after {
      font-size: .7em;
      padding: .26em 0 0;
    }

    &:nth-of-type(1) {
      right: 0px;
      top: 10px;
      transform: translateX(50%);
      transition: transform 1.6s;

      @include notebook {
        top: 0px;
      }
    }

    &:nth-of-type(2) {
      bottom: 10px;
      left: 0px;
      transform: translateX(-50%);
      transition: transform 1.6s;

      @include notebook {
        bottom: 0px;
      }
    }
  }

  &:hover .text-link__wrapper:nth-of-type(1) {
    transform: translateX(-50%);
  }

  &:hover .text-link__wrapper:nth-of-type(2) {
    transform: translateX(50%);
  }

  .link--plain {
    .link__icon--after {
      height: 50px;
      padding: 0;
      vertical-align: baseline;
      width: unset;

      @include landscape {
        height: 100px;
      }
    }
  }
}
