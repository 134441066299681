
.pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  text-align: center;

  @include notebook {
    justify-content: center;
  }

  .number-button {
    margin: 0 5px;
  }

  .number-button--selected,
  .number-button--dots {
    pointer-events: none;
  }

  .number-button--selected {
    font-weight: bold;
  }

  .pagination__previous {
    margin-right: 15px;
  }

  .pagination__next {
    margin-left: 15px;
  }

  .pagination__numbers {
    position: relative;
  }

  .link {
    align-items: center;
    display: inline-flex;
    min-height: 26px;
  }

  .link__span {
    display: none;
  }
}
