
:root {
  --heading-3-font-size: 20px;
  --heading-3-font-stack: #{$ivardisplay-font-stack};
  --heading-3-font-style: normal;
  --heading-3-font-variant: normal;
  --heading-3-font-weight: #{get-ivardisplay-weight(regular)};
  --heading-3-letter-spacing: -.002em;
  --heading-3-line-height: 1.3em;
  --heading-3-margin-bottom: .5em;
  --heading-3-margin-top: 1.25em;

  @include portrait {
    --heading-3-font-size: 24px;
    --heading-3-line-height: 1.25em;
  }

  @include landscape {
    --heading-3-font-size: 30px;
    --heading-3-line-height: 1.2em;
  }

  @include desktop {
    --heading-3-font-size: 36px;
  }
}
