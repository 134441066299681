
:root {
  --richtext-heading-1-font-size: 65px;
  --richtext-heading-1-font-stack: #{$ivardisplay-font-stack};
  --richtext-heading-1-font-style: normal;
  --richtext-heading-1-font-variant: normal;
  --richtext-heading-1-font-weight: #{get-ivardisplay-weight(regular)};
  --richtext-heading-1-letter-spacing: -.01em;
  --richtext-heading-1-line-height: 1.2em;
  --richtext-heading-1-margin-bottom: .5em;
  --richtext-heading-1-margin-top: 1em;
  --richtext-heading-1-word-spacing: normal;

  @include portrait {
    --richtext-heading-1-font-size: 65px;
    --richtext-heading-1-line-height: 1.15em;
  }

  @include landscape {
    --richtext-heading-1-font-size: 180px;
    --richtext-heading-1-line-height: 1.1em;
  }
}
