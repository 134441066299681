
:root {
  --swiper-captions-font-size: 16px;
  --swiper-captions-font-stack: #{$ivartext-font-stack};
  --swiper-captions-font-style: italic;
  --swiper-captions-font-variant: normal;
  --swiper-captions-font-weight: #{get-ivartext-weight(italic)};
  --swiper-captions-letter-spacing: 0;
  --swiper-captions-line-height: 1.45em;
  --swiper-captions-margin-bottom: .5em;
  --swiper-captions-margin-top: 1em;

  @include portrait {
    --swiper-captions-font-size: 16px;
    --swiper-captions-line-height: 1.4em;
  }

  @include landscape {
    --swiper-captions-font-size: 16px;
    --swiper-captions-line-height: 1.4em;
  }
}
