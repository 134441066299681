
:root {
  --richtext-heading-3-font-size: 22px;
  --richtext-heading-3-font-stack: #{$ivardisplay-font-stack};
  --richtext-heading-3-font-style: normal;
  --richtext-heading-3-font-variant: normal;
  --richtext-heading-3-font-weight: #{get-ivardisplay-weight(regular)};
  --richtext-heading-3-letter-spacing: -.002em;
  --richtext-heading-3-line-height: 1.3em;
  --richtext-heading-3-margin-bottom: .5em;
  --richtext-heading-3-margin-top: 1.25em;

  @include portrait {
    --richtext-heading-3-font-size: 22px;
    --richtext-heading-3-line-height: 1.25em;
  }

  @include landscape {
    --richtext-heading-3-font-size: 40px;
    --richtext-heading-3-line-height: 1.2em;
  }

  @include desktop {
    --richtext-heading-3-font-size: 48px;
  }
}
