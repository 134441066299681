$height: 54px;

@mixin icon {
  display: block;
  height: 100%;
  width: 100%;

  > svg {
    display: block;
    fill: currentColor;
    height: 100%;
    width: 100%;
  }
}

.toggle-button {
  @include link-shadow;
  @include typography(button);

  border: 0;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  height: $height;
  overflow: hidden;
  padding: 6px 10px;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: $height;

  .toggle-button__icon-on {
    @include icon;
  }

  .toggle-button__icon-off {
    @include icon;
  }
}

.toggle-button--active {
  .toggle-button__icon-off {
    display: none;
  }
}

.toggle-button:not(.toggle-button--active) {
  .toggle-button__icon-on {
    display: none;
  }
}
