
.video-embed {
  height: auto;
  position: relative;
  width: 100%;

  .video-embed__container {
    overflow: hidden;
    position: relative;
    width: 100%;

    .picture {
      height: auto;
      opacity: 1;
      width: 100%;
    }
  }

  .video-embed__caption {
    @include typography(caption);
  }

  .video-embed__video,
  .video-embed__hit-target {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video-embed__video {
    background-color: $color-black;
  }

  .video-embed__hit-target {
    cursor: pointer;
  }

  .video-embed__button {
    color: $color-white;
    display: none;
    font-size: 3.7rem;
    left: 50%;
    line-height: 1em;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1em;

    @include notebook {
      font-size: 4rem;
    }

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .video-embed__iframe,
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity $easing-change-slower;
    width: 100%;
  }

  &.video-embed--loaded {
    .video-embed__hit-target {
      display: none;
    }
  }

  &:not(.video-embed--loaded) {
    .video-embed__video {
      filter: brightness(.66) contrast(1.1);
    }

    .video-embed__button--play {
      display: block;
    }
  }
}
