
:root {
  --heading-2-font-size: 24px;
  --heading-2-font-stack: #{$maisonneue-font-stack};
  --heading-2-font-style: normal;
  --heading-2-font-variant: normal;
  --heading-2-font-weight: #{get-maisonneue-weight(book)};
  --heading-2-letter-spacing: -.005em;
  --heading-2-line-height: 1.25em;
  --heading-2-margin-bottom: .5em;
  --heading-2-margin-top: 1em;

  @include portrait {
    --heading-2-font-size: 32px;
    --heading-2-line-height: 1.2em;
  }

  @include landscape {
    --heading-2-font-size: 40px;
    --heading-2-line-height: 1.15em;
  }
}
