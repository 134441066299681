
.article-video {
  margin: var(--margin) 0;
  position: relative;
}

.article-video--align-default {
  .article-video__grid {
    @include grid-container;
  }

  .article-video__wrapper {
    grid-column: var(--layout-content);
    margin-right: auto;
  }
}

.article-video--align-full {
  .article-video__grid {
    @include grid-container;
  }

  .article-video__wrapper {
    grid-column: var(--layout-full);
  }
}

.article-video--align-fullbleed {
  .video__caption {
    padding: 0 calc(var(--grid-gutter) + var(--grid-margin));
  }
}
